import React, { useEffect, useState, useContext } from "react"
import axios from "axios"
import classNames from "classnames"
import { parseISO, getMinutes } from "date-fns"
import { formatToTimeZone } from "date-fns-timezone"
import slug from "slug"

import AccountContext from "~context/account"

/* Import Global Component(s) */
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Local Style(s) */
import "./day.scss"

import DayItem from './dayItem'

const ProgramDay = ({ date, events, tokenString }) => {

  const [accountInfo] = useContext(AccountContext)

  const [filteredEvents, setFilteredEvents] = useState(events.filter(event => !event.vip))
  const [isLoggedIn, setLoggedIn] = useState(false)



  useEffect(() => {
    
    setLoggedIn((accountInfo) ? true : false)

    const accountPresent = (accountInfo) ? true : false
    

    const fEvents = accountPresent
      ? events
      : events.filter(event => !event.vip)



    setFilteredEvents(fEvents)


  }, [setFilteredEvents, accountInfo, events, isLoggedIn, setLoggedIn])




  // Uses 1.29 of date-fns formatting
  const formatWithTimezone = (date, displayFormat) => {
    const storedDate = new Date(date)

    const timeZone = "America/New_York"
    const displayedTime = formatToTimeZone(storedDate, displayFormat, {
      timeZone,
    })
    return `${displayedTime}`
  }

  const generateEventTime = (startDatetime, endDatetime) => {
    const startDate = startDatetime
    if (endDatetime) {
      const endDate = endDatetime
      if (
        formatWithTimezone(startDate, "a") !== formatWithTimezone(endDate, "a")
      ) {
        return `${
          getMinutes(parseISO(startDate)) === 0
            ? formatWithTimezone(startDate, "ha")
            : formatWithTimezone(startDate, "h:mma")
        }–${
          getMinutes(parseISO(endDate)) === 0
            ? formatWithTimezone(endDate, "ha")
            : formatWithTimezone(endDate, "h:mma")
        }`
      } else {
        return `${
          getMinutes(parseISO(startDate)) === 0
            ? formatWithTimezone(startDate, "h")
            : formatWithTimezone(startDate, "h:mm")
        }–${
          getMinutes(parseISO(endDate)) === 0
            ? formatWithTimezone(endDate, "ha")
            : formatWithTimezone(endDate, "h:mma")
        }`
      }
    } else {
      return getMinutes(parseISO(startDate)) === 0
        ? formatWithTimezone(startDate, "ha")
        : formatWithTimezone(startDate, "h:mma")
    }
  }

  console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)

  return (
    <div className="program-day">
      <h3 className="date">{date}</h3>
      <ul>
        {filteredEvents.map(event => (
            event ? <DayItem key={slug(event.title)} event={event} isLoggedIn={isLoggedIn} tokenString={tokenString} /> : null
          )
        )}
      </ul>
    </div>
  )
}

export default ProgramDay
