import React, { useEffect, useState, useContext, useRef } from "react"
import classnames from "classnames"
import { useForm, Controller } from "react-hook-form"

import Card from "~components/card/card"

/* Import Alert Style(s) */
import "~components/pages/index/components/alertButton/alert-button.scss"

/* Import Local Style(s) */
import "./dayModal.scss"

const DayModal = ({ event, handleClick }) => {

	const [showForm, setShowForm ] = useState(false)

	const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const formRef = useRef()


	const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm()

  const onSubmit = data => {

  	const { guest_email, guest_firstname, guest_lastname } = data

  	handleClick({
  		isGuest: true,
  		guest_firstname,
  		guest_lastname,
  		guest_email
  	})

  }


	return(<div className="day-modal--interior">
		
		
			{!showForm && <button className="alert-buttons" onClick={handleClick}>
				      <Card backgroundColor="#c7c7c7">
				        <p className="font-size--small">{(event.rsvp && event.rsvp.isAtCapacity) ? 'Join Waitlist' : 'RSVP'}</p>
				      </Card>
				    </button>}

	    {!showForm && <button className="alert-buttons" onClick={() => setShowForm(true)}>
	    	      <Card backgroundColor="#c7c7c7">
	    	        <p className="font-size--small">{(event.rsvp && event.rsvp.isAtCapacity) ? 'Join Waitlist' : 'RSVP'} with +1</p>
	    	      </Card>
	    	    </button>}

	    {showForm && <form
          onSubmit={handleSubmit(onSubmit)}
          className={classnames("plus-one-form mailing-list", "font-size--small")}
          ref={formRef}
         >
          	
          	<fieldset className="grid">
	            <input
	              type="text"
	              className={ (errors.guest_firstname) ? 'input_error col col--1_2' : 'col col--1_2' }
	              placeholder="Guest First Name*"
	              {...register("guest_firstname", { required: true })}
	            />

	            <input
	              className={ (errors.guest_lastname) ? 'input_error col col--1_2  col--end' : 'col col--1_2  col--end' }
	              type="text"
	              placeholder="Guest Last Name*"
	              {...register("guest_lastname", { required: true })}
	            />
	          </fieldset>

	          <input
	            type="email"
	            className={ (errors.email) ? 'input_error' : '' }
	            placeholder="Guest Email Address*"
	            {...register("guest_email", { 
	              required: true,
	              pattern: /(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,15}/
	            })}
	          />

	          {!isSubmitting && <input type="submit" value="Submit RSVP" />}

          	{isSubmitting && <p className="font-size--small left">Submitting...</p>}

        </form>}

		 

	</div>)

}

export default DayModal